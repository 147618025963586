.markdown {
  line-height: 1.7;
  margin: 0.5rem 0.5rem;
  font-size: 0.875rem;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Fira Sans", sans-serif;
    border-bottom-width: 1px;
    font-weight: 600;
    padding-left: 0.25rem;
    position: relative;
    color: #2563eb !important; /* blue-600 */
    border-color: #bfdbfe !important; /* blue-200 */
  }

  a {
    border-style: dashed;
    border-bottom-width: 1px;
    border-color: #3b82f6; /* blue-500 */
    color: #3b82f6; /* blue-500 */
  }

  ul > li {
    padding-left: 0px;
  }

  ol {
    list-style-type: decimal;
    padding-left: 1rem;
  }

  ol > li {
    padding-left: 0px;
  }

  img {
    max-width: 300px;
    max-height: 300px;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  table {
    width: 100%;
    border-width: 1px;
    border-color: #6ee7b7; /* gray-300 */
    padding: 0.25rem;
    thead {
      background-color: #f3f4f6; /* gray-100 */
      th {
        text-align: left;
        &:first-child {
          padding-left: 0.5rem;
        }
      }
    }
    tbody tr:nth-child(even) {
      background-color: #f9fafb; /* gray-50 */
    }
    td,
    th {
      vertical-align: top;
      padding: 0.5rem;
      border-width: 1px;
      border-color: #6ee7b7; /* gray-300 */
    }
    tbody td:first-child {
      padding-left: 0.5rem;
    }
  }

  pre {
    margin: 0.5rem auto;
    padding: 0.5rem 1rem;
    background-color: #f9fafb; /* gray-50 */
    border-left-width: 1rem;
    border-color: #93c5fd; /* blue-300 */
    /* font-size: 1rem; */
    overflow-x: auto;
  }

  ul {
    margin-left: 1rem;
    color: #3730a3; /* indigo-800 */
    list-style-type: none;
    li {
      border-bottom: 1px dashed #eee;
      color: #1f2937; /* gray-800 */
      padding: 0.25rem auto;

      & > *:first-child {
        margin-top: 0;
      }

      p {
        display: inline-block;
        padding: 0px;
        margin-bottom: 0px;
      }
    }

    & > li > ul {
      margin-top: 0px !important;
      margin-bottom: 0px !important;
    }

    & > li > ul > li {
      &:first-child {
        margin-top: 0px;
      }
      &:last-child {
        margin-bottom: 0px;
      }

      &::before {
        content: "-"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
        color: #1e40af; /* blue-800 */
      }
    }

    li:last-of-type {
      border: none;
      padding-bottom: 0;
    }

    li > p::first-line {
      color: #6b7280; /* gray-500 */
      font-weight: 700;
    }
    li::before {
      content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
      color: #1e40af; /* blue-800 */
      display: inline-block; /* Needed to add space between the bullet and the text */
      width: 1em; /* Also needed for space (tweak if needed) */
      margin-left: -1em; /* Also needed for space (tweak if needed) */
      font-weight: 900;
      margin-top: -0.2em;
      font-size: 1.2em;
      vertical-align: top;
    }
  }

  h2 {
    font-size: 1.3rem;
  }
  h3 {
    font-size: 1.2rem;
  }
  h4 {
    font-size: 1.1rem;
  }
}

[data-color-mode="dark"] {
  .markdown {
    ul > li {
      color: #D1D5DB; /* gray-300 */
    }

  pre {
    background-color: #1F2937; /* gray-800 */
    border-color: #374151; /* gray-600 */
  }

  table {
    border-color: #374151; /* gray-600 */
    thead {
      background-color: #1F2937; /* gray-800 */
    }
    tbody tr:nth-child(even) {
      background-color: #1F2937; /* gray-800 */
    }
    td,
    th {
      border-color: #374151; /* gray-600 */
    }
  }
}
}
